<template>
  <div>
    <h1>Crawler Abdeckung</h1>
    <div>
      <v-card min-height="435">
        <div style="width: 100%; text-align: center; padding-top: 150px" v-if="!loaded">
          <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
        </div>
        <v-container>
          <v-row>
            <v-btn :class="{ primary: crawlerIndex === i }" v-for="(element, i) in yAxisArray" :key="i" @click="crawlerIndex = i" style="margin: 5px">
              {{ element.day ? element.day.split('T')[0].split('-').reverse().join('.') : '' }}
            </v-btn>
          </v-row>
        </v-container>
        <apexchart
          type="bar"
          height="350"
          :options="crawlerChartOptions"
          :series="crawlerSeries"
          v-if="loaded"
        ></apexchart>

        <div style="margin-left: 20px" v-if="loaded">
          <h2 class="text--text">Durchschnitt</h2>
          <v-row style="margin-left: 0px; margin-top: 5px">
            <div style="width: 15px; height: 15px; background-color: #febf7c"></div><div class="caption" style="line-height: 16px; margin-left: 10px; margin-right: 10px">14 Tage</div>
            <div style="width: 15px; height: 15px;" class="success"></div><div class="caption" style="line-height: 16px; margin-left: 10px">7 Tage</div>
          </v-row>
        </div>
        <apexchart
          type="line"
          height="350"
          :options="crawlerChartOptions"
          :series="crawlerSeriesAverage"
          v-if="loaded"
        ></apexchart>
      </v-card>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'vue-apexcharts'
import axios from 'axios'

export default {
  data () {
    return {
      loaded: false,
      averageData: [],
      averageDataWeek: [],
      // Crawler Chart
      crawlerIndex: 0,
      xAxis: [],
      yAxisArray: [],
      // Crawler Chart Options
      crawlerChartOptions: {
        plotOptions: {
          bar: {
            borderRadius: 6,
            columnWidth: '45%',
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [
          ],
          labels: {
            style: {
              fontSize: '12px'
            }
          }
        }
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    crawlerSeries () {
      if (this.loaded) {
        return [
          {
            name: 'Fälle',
            data: this.yAxisArray[this.crawlerIndex].data.slice(1)
          }
        ]
      } else {
        return []
      }
    },
    crawlerSeriesAverage () {
      if (this.loaded) {
        return [
          {
            name: '14 Tage',
            type: 'line',
            color: '#febf7c',
            data: this.averageData.slice(1)
          },
          {
            name: '7 Tage',
            type: 'line',
            color: '#24bf77',
            data: this.averageDataWeek.slice(1)
          }
        ]
      } else {
        return []
      }
    }
  },
  methods: {
    loadCrawlerData () {
      axios.get('/statistics/crawler/casesStatById')
        .then(res => {
          this.crawlerChartOptions.xaxis.categories = res.data.xAxis.slice(1)
          this.yAxisArray = res.data.yAxis.reverse()
          this.crawlerIndex = res.data.yAxis.length - 1
          this.loaded = true
          this.averageData = this.computeAverageData(14)
          this.averageDataWeek = this.computeAverageData(7)
        })
        .catch(error => {
          console.log(error)
        })
    },
    computeAverageData (days) {
      var averageArr = []
      this.yAxisArray[0].data.forEach(() => {
        averageArr.push(0)
      })

      var start = days > 7 ? 0 : days
      for (let j = start; j < this.yAxisArray.length; j++) {
        for (let i = 0; i < this.yAxisArray[j].data.length; i++) {
          averageArr[i] += this.yAxisArray[j].data[i]
        }
      }

      var arrLength = days > 7 ? this.yAxisArray.length : days
      for (let i = 0; i < averageArr.length; i++) {
        averageArr[i] = (averageArr[i] / arrLength)
        averageArr[i] = Math.round((averageArr[i] + Number.EPSILON) * 100) / 100
      }
      return averageArr
    }
  },
  created () {
    this.loadCrawlerData()
  },
  components: {
    apexchart: ApexCharts
  }
}
</script>

<style scoped>
h1 {
  margin-bottom: 20px
}
</style>
